@import 'assets/styles/_variables.scss';
// .avatar-container {
//     position: relative;
//     width: 64px;
//     height: 64px;

//     .avatar,
//     .avatar-background {
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         object-fit: cover;
//     }
//     .top-left {
//         background-color: #895e4d;
//         position: absolute;
//         width: 0.5em;
//         height: 0.5em;
//         top: 3px;
//         left: 3px;
//         border-bottom-right-radius: 75%;
//         border-top-left-radius: 50%;
//     }
//     .top-right {
//         background-color: #895e4d;
//         position: absolute;
//         width: 0.5em;
//         height: 0.5em;
//         top: 3px;
//         right: 2px;
//         border-bottom-left-radius: 75%;
//         border-top-right-radius: 50%;
//     }
//     .bottom-left {
//         background-color: #895e4d;
//         position: absolute;
//         width: 0.5em;
//         height: 0.5em;
//         bottom: 2px;
//         left: 2px;
//         border-top-right-radius: 75%;
//         border-bottom-left-radius: 80%;
//     }
//     .bottom-right {
//         background-color: #895e4d;
//         position: absolute;
//         width: 0.5em;
//         height: 0.5em;
//         bottom: 2px;
//         right: 2px;
//         border-top-left-radius: 75%;
//         border-bottom-right-radius: 80%;
//     }
//     .edit-pencil {
//         position: absolute;
//         bottom: -10px;
//         right: -10px;
//     }
// }

// .small {
//     width: 22px;
//     height: 22px;
//     .avatar {
//         width: 19px;
//         height: 19px;
//     }
//     img {
//         width: 100%;
//         height: 100%;
//         vertical-align: unset;
//     }
//     .top-left {
//         width: 4px;
//         height: 4px;
//         top: 1px;
//         left: 1px;
//     }
//     .top-right {
//         width: 4px;
//         height: 4px;
//         top: 1px;
//         right: 1px;
//     }
//     .bottom-left {
//         width: 4px;
//         height: 4px;
//         bottom: 1px;
//         left: 1px;
//     }
//     .bottom-right {
//         width: 4px;
//         height: 4px;
//         bottom: 1px;
//         right: 1px;
//     }
// }

// .spot-list-avatar {
//     width: 24px;
//     height: 24px;
//     .avatar {
//         width: 19px;
//         height: 19px;
//     }
//     img {
//         width: 100%;
//         height: 100%;
//         vertical-align: unset;
//     }
//     .top-left {
//         width: 4px;
//         height: 4px;
//         top: 1px;
//         left: 1px;
//     }
//     .top-right {
//         width: 4px;
//         height: 4px;
//         top: 1px;
//         right: 1px;
//     }
//     .bottom-left {
//         width: 4px;
//         height: 4px;
//         bottom: 1px;
//         left: 1px;
//     }
//     .bottom-right {
//         width: 4px;
//         height: 4px;
//         bottom: 1px;
//         right: 1px;
//     }
// }
.background-layer-1 {
    background-color: #844e33;
    height: 96px;
    width: 96px;
    border-radius: 15%;
    position: relative;
    .background-layer-2 {
        background-color: $detail-color-default;
        height: 91px;
        width: 91px;
        border-radius: 15%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .avatar-wrap{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0.5px;
        .top-left {
            background-color: $detail-color-default;
            position: absolute;
            width: 0.5em;
            height: 0.5em;
            top: -1%;
            left: -1%;
            border-bottom-right-radius: 75%;
            // border-top-left-radius: 50%;
        }
        .top-right {
            background-color: $detail-color-default;
            position: absolute;
            width: 0.5em;
            height: 0.5em;
            top: -1%;
            right: -1%;
            border-bottom-left-radius: 75%;
            // border-top-right-radius: 50%;
        }
        .bottom-left {
            background-color: $detail-color-default;
            position: absolute;
            width: 0.5em;
            height: 0.5em;
            bottom: -1%;
            left: -1%;
            border-top-right-radius: 75%;
            // border-bottom-left-radius: 80%;
        }
        .bottom-right {
            background-color: $detail-color-default;
            position: absolute;
            width: 0.5em;
            height: 0.5em;
            bottom: -1%;
            right: -1%;
            border-top-left-radius: 75%;
            // border-bottom-right-radius: 80%;
        }
    }
}
