@import '/src/assets/styles/variables';

.my-spot-item-wrapper {
    .myspot-item {
        border-radius: 32px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(6px);
        padding: 12px;
        align-items: center;
        .spot-category {
            padding: 0px;
            margin: 0px;
            font-size: 12px;
            font-weight: 700;
            color: #77b4b5;
            line-height: 32px;
            padding-right: 12px;
            height: 20px;
            display: flex;
            align-items: center;
        }
        .border-left {
            padding-left: 10px;
            border-left: 1px solid #e5e5ea;
        }

        .image-spot {
            border-radius: 24px;
            object-fit: cover;
            margin-right: 16px;
        }

        .myspot-title {
            color: #000;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;
        }

        .count-container {
            display: flex;
            width: 65px;
            padding: 4px 12px 4px 4px;
            align-items: center;
            gap: 8px;
            border-radius: 1000px;
            background: #fff;
            height: 28px;
            width: 65px;

            .text-icon {
                color: #000;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}
