.gm-style-mtc,
.gm-style-cc,
.gm-svpc,
img[alt='Google'] {
    display: none;
}

.map-icon-custom {
    padding: 5px !important;
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.8) !important;
}
.full-screen {
    padding: 10px 0;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 22px !important;
}

.map-control-position-css {
    position: absolute;
    left: 46%;
    bottom: 0px;
    font-size: 11px;
    width: 40px;
    height: 73px;
}

.custom-marker {
    position: relative;
    width: 56px;
    height: 63px;
    .marker-photo {
        top: 8px;
        left: 8px;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
}

.gm-style-iw-c {
    display: none !important;
}

.gm-style-iw-tc {
    display: none !important;
}
