@import '/src/assets/styles/variables';

.change-degree-container {
    .select-degree {
        .css-1fdsijx-ValueContainer {
            padding-left: 35px !important;
        }
        .css-hlgwow {
            margin-left: 35px !important;
        }
        .css-190355g-singleValue {
            text-align: start;
        }
    }
    .degree_name {
        font-size: 16px;
        color: $detail-color-default;
    }
    .frame-container {
        .frame-border {
            .frame-item {
                padding: 0;
            }
        }
    }
}
