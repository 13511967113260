@import 'assets/styles/_variables.scss';

.frame-highlight-title-container {
    background: $border-frame;
    border-radius: 8px;
    .frame-highlight-title-border {
        background-image: url('../../assets/images/FrameTitle.png');
        background-size: 100% 100%;
        border-radius: 8px;
        padding: 6px;

        .frame-highlight-title-item {
            background: #fff;
            border-radius: 8px;
            position: relative;

            .top-left {
                background-color: $border-frame;
                position: absolute;
                width: 0.5em;
                height: 0.5em;
                top: 0;
                left: 0;
                border-bottom-right-radius: 75%;
                border-top-left-radius: 50%;
            }
            .top-right {
                background-color: $border-frame;
                position: absolute;
                width: 0.5em;
                height: 0.5em;
                top: 0;
                right: 0;
                border-bottom-left-radius: 75%;
                border-top-right-radius: 50%;
            }
            .bottom-left {
                background-color: $border-frame;
                position: absolute;
                width: 0.5em;
                height: 0.5em;
                bottom: 0;
                left: 0;
                border-top-right-radius: 75%;
                border-bottom-left-radius: 80%;
            }
            .bottom-right {
                background-color: $border-frame;
                position: absolute;
                width: 0.5em;
                height: 0.5em;
                bottom: 0;
                right: 0;
                border-top-left-radius: 75%;
                border-bottom-right-radius: 80%;
            }
        }
    }
}
