@import '/src/assets/styles/variables';

.header-container {
    margin: auto;
    padding: 16px 12px;
    border-radius: 0px 0px 40px 40px;
    background: #FFF;
    backdrop-filter: blur(16px);
    .banner-header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        color: #000;
        
    }
}
