@import 'assets/styles/_variables.scss';

.input-custom {
    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px white inset;
        }
        input {
            width: 100%;
            height: 50px;
            padding: 12px 16px;
            background: #fcfcfc;
            border: 1px solid $detail-color-default;
            border-radius: 100px;
            &.input-error {
                border: 1px solid red;
            }
            &:focus {
                border: 1px solid #42bdbf;
                outline: 0 none;
            }
        }
        .map-input-value {
            padding-left: 40px !important;
        }

        .clear-input-value {
            padding-right: 40px !important;
        }
        .suffix-container {
            position: absolute;
            right: 8px;
            top: 10px;
        }
        .map-input-container {
            position: absolute;
            left: 16px;
            top: 10px;
        }

        .clear-input-container {
            position: absolute;
            right: 16px;
            top: 10px;
        }
    }
    ::-webkit-input-placeholder {
        /* Edge */
        color: #b5b3b3;
        font-size: 14px;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b5b3b3;
        font-size: 14px;
    }

    ::placeholder {
        color: #b5b3b3;
        font-size: 14px;
    }
}
