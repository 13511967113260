.points-exchange-location {
    padding: 4px;
    margin-top: 18px;
    .title {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .address {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;
    }
    .map {
        height: 208px;
        border-radius: 32px;
        overflow: hidden;
        width: 100%;
    }
}

.location-exchange-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 18px;
}

.location-description {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #3c3c3c;
    white-space: pre-wrap;
}
.map-link {
    margin-top: 16px;
    text-align: center;
}
