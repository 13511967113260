.spotdetail-location {
    padding: 4px;
    .title {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .address {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;
    }
    .map {
        height: 208px;
        border-radius: 32px;
        overflow: hidden;
    }
}

.location-title {
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 12px;
    font-size: 18px;
}

.location-des {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
.map-link {
    margin-top: 16px;
    text-align: center;
}
