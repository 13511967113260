.spotdetail-users-comment {
    font-size: 14px;
    padding: 8px;
    .title {
        display: flex;
        align-items: center;
    }
    .input-comment-container {
        .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
        }
        .input-custom {
            width: 100%;
        }
    }
    input:not(:placeholder-shown) ~ .post-btn {
        background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
        box-shadow: -3px 3px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
        border-radius: 8px;
        color: var(--bs-btn-hover-color);
    }
    .list-users-comment {
        margin-top: 5px;
        padding-top: 10px;
        padding-left: 4px;
        padding-right: 4px;
        max-height: 190px;
        overflow: auto;
    }
}
