/* Create this CSS file with the purpose of avoiding the need to compile from SCSS to CSS */
/* canvas */
.AR {
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3 !important;
}
.AR-back-icon {
    position: absolute;
    top: 28px;
    left: 15px;
    z-index: 4;
}
.AR-meter {
    position: fixed;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    background-color: white;
    color: #e59f21;
    border: 2px solid #e59f21;
    padding: 5px 10px;
    min-width: 250px;
    text-align: center;
    z-index: 4;
    display: none;
}
/* video (camera) */
#arjs-video {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1 !important;
}
/* override css of LIB */
body {
    width: unset !important;
    margin-left: unset !important;
    margin-top: unset !important;
    height: unset !important;
}
body:has(#arjs-video) {
    overflow: hidden;
}
