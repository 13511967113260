@import '/src/assets/styles/variables';

.stage-action-item {
    position: relative;
    background: white;
    border-radius: 32px;
    padding: 8px 16px 8px 8px;

    .image-stage-action {
        margin-top: 4px;
        border-radius: 16px;
        cursor: pointer;
        object-fit: cover;
        width: 100%;
        max-width: 60px;
        max-height: 60px;
        aspect-ratio: 1 / 1;
    }
    .mission-name {
        font-weight: bold;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 13px;
        max-width: 240px;
    }
    .mission-detail {
        font-size: 12px;
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .close-btn {
        position: absolute;
        right: 20px;
    }
}

.stage-action-opacity {
    opacity: 0.5;
    cursor: auto;
}
