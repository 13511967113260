@import '/src/assets/styles/variables';
.spotdetail-remark {
    .explanation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 100px;
        padding: 12px 16px;
        background: #fcfcfc;
        border: 1px solid #e5e5ea;
        margin: 12px 0;
    }

    .show-explanation {
        max-height: 500px;
        transition: max-height 0.3s ease-out;
    }

    .hide-explanation {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }
}
