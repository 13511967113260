.gm-style-mtc,
.gm-style-cc,
.gm-svpc,
img[alt='Google'] {
    display: none;
}

.map-icon-custom {
    padding: 5px !important;
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.8) !important;
}
.full-screen {
    padding: 10px 0;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 22px !important;
}
/*
.custom-marker {
    position: relative;
    width: 56px;
    height: 63px;
    .marker-photo {
        top: 8px;
        left: 8px;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
}
*/

.custom-marker {
    position: relative;
    .img-marker {
        top: 9%;
        left: 14%;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: #fff;
        color: #0e5153;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
    }
}

.gm-style-iw-c {
    display: none !important;
}

.gm-style-iw-tc {
    display: none !important;
}
