@import '/src/assets/styles/variables';

.form-check-input-radio {
    width: 20px;
    height: 20px;
    &:checked {
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2342BDBF'/%3e%3c/svg%3e") !important;
        border: 2px solid #42bdbf;
    }
    &:focus {
        box-shadow: none;
        border: 2px solid #42bdbf;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2342BDBF'/%3e%3c/svg%3e") !important;
    }
}

.form-check-label-radio {
    margin-left: 8px;
    font-size: 14px;
    max-width: 152px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.text-error {
    font-size: 12px;
    color: red;
}
