@import '/src/assets/styles/variables';
.spotdetail-upload {
    padding: 8px;
    .title {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .custom-file-image-upload {
        background-color: white;
        padding: 67px 12px;
        border: 1px dashed #42bdbf;
        border-radius: 32px;
        cursor: pointer;
        white-space: pre-wrap;
        font-size: 14px;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .image-spot {
        border-radius: 8px;
        object-fit: cover;
    }
    .permission-container {
        position: fixed;
        z-index: 99999999999999999999999999999999999999;
        top: 0;
    }
}
