.current-location-btn {
    transform: translateX(0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .current-location {
        box-shadow: 2px 4px 9.3px 0px #080e0e29;
        background: #0000003d;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;
    }
}
