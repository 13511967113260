@import '/src/assets/styles/variables';

.confirm-modal-container {
    border-radius: 32px;
    .title {
        text-align: center;
        white-space: pre-wrap;
        font-weight: bold;
        margin-bottom: 32px;
    }
}
