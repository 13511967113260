@import '/src/assets/styles/variables';

.title-name {
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
}
.title-note {
    color: #d22727;
    font-weight: bold;
    font-size: 14px;
}

.icon-title {
    border: 4px solid #e0feff;
    background-color: $detail-color-default;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}
