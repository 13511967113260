@import '/src/assets/styles/variables';
.actiondetail-describe {
    .actiondetail-image-coverage {
        position: relative;
        width: 100%;
    }
    .title {
        font-weight: 700;
        font-size: 18px;
        color: $text-color;
        text-align: center;
    }
    .actiondetail-image {
        position: relative;
        margin-bottom: 10px;
        img {
            max-height: 11.5rem;
            min-height: 11.5rem;
            width: 100%;
            object-fit: cover;
            border-radius: 32px 32px 0 0;
            overflow: hidden;
        }
    }

    .actiondetail-image::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 63.22%, #ffffff 100%);
        width: 100%;
        height: 100%;
    }

    .describe {
        width: 100%;
        .desc {
            white-space: pre-wrap;
            font-size: 14px;
            font-weight: 400;
        }
    }
}
