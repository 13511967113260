.spotdetail-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    .left-block {
        display: flex;
        gap: 14px;
    }
    .right-block {
        display: flex;
        align-items: center;
        gap: 4px;
        .icon {
            cursor: pointer;
        }
    }
    .user-info {
        display: flex;
        align-items: center;
        gap: 4px;
        .user-name {
            font-size: 12px;
        }
        .avatar-box{
            font-size: 7px;
        }
    }
    .clear-status {
        display: flex;
        align-items: center;
        padding: 2px 12px;
        width: 64px;
        height: 22px;
        font-size: 10px;
        border-radius: 8px;
        color: #fff;
    }
    .not-clear {
        background: linear-gradient(0deg, #a3314b 0%, #d0627b 95.83%);
        box-shadow: -1px 2px 0px #56202c, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
    }

    .cleared {
        background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
        box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
    }
}
