.actiondetail-location {
    padding: 8px;
    .title {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .address {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;
    }
    .map {
        min-width: calc(320px - 12px);
        height: 178px;
        margin-top: 10px;
    }
}
