@import '/src/assets/styles/variables';

.ovelay-spiner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background-color: #fff;
    // opacity: 0.8;
    opacity: 1;
    display: none;
}

.ovelay-spiner.show {
    display: block;
}

.loader {
    margin: auto;
    border: 10px solid #eaf0f6;
    border-radius: 50%;
    border-top: 10px solid $detail-color-default;
    width: 40px;
    height: 40px;
    animation: spinner 1s linear infinite;
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
