@import 'assets/styles/_variables.scss';

.info-box-action-container {
    position: relative;
    margin-bottom: 18px;
    font-family: $default-font-family;
    background: $background-frame-color-default-2;
    box-shadow: inset -1px -1px 1px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 6px 6px 4px 6px;
    .info-box {
        overflow-x: hidden;
        padding: 8px;
        background: $background-frame-color-default;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
            inset 0px 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        .close-btn {
            text-align: end;
            margin-bottom: 16px;
        }
        .status-btn {
            .spot-status {
                min-width: 64px;
                height: 24px;
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: 10px;
                color: #fff;
                border-radius: 8px;
            }
            .spot-status.active {
                background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
                box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
                border-radius: 8px;
            }
        }
        .first-info-item {
            margin-left: 8px;
            width: 100%;
            display: flex;
            .spot-info {
                display: flex;
                align-items: center;
                padding-right: 0;
                min-width: 138px;
            }
            .spot-name {
                font-size: 10px;
                font-weight: 400;
                margin-left: 4px;
                margin-right: 6px;
            }
        }
        .spot-item-detail {
            margin-top: 8px;
            display: flex;
            .image-spot {
                border-radius: 4px;
                margin-right: 8px;
                object-fit: cover;
            }
            .spot-title {
                font-size: 16px;
                color: $detail-color-default;
                max-height: 48px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .spot-comment {
                font-size: 12px;
                color: #414040;
                max-height: 42px;
                margin-bottom: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .spot-category-container {
                gap: 4px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                .spot-category {
                    background: $border-frame;
                    border: 1px solid $detail-color-default;
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), -1px 1px 0px $detail-color-default,
                        inset 2px 2px 4px rgba(247, 240, 222, 0.4);
                    border-radius: 8px;
                    padding: 2px 10px;
                    font-size: 10px;
                    align-items: center;
                    text-align: center;
                    color: #fff;
                    height: 24px;
                    max-width: 180px;
                    .category-text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }
                }
            }
        }
        .icon-wrapper {
            margin-top: 7px;
            margin-left: 6px;
            .icon {
                display: flex;
                align-items: center;
                .spot-icon {
                    margin-left: 4px;
                    font-size: 10px;
                    color: #414040;
                }
            }
        }
    }
    .info-box-tail {
        position: absolute;
        bottom: -18px;
        left: 140px;
    }
}
