@import '/src/assets/styles/variables';

.ranking-item-wrapper {
    border-width: 1px;
    border-style: solid;
    padding: 4px 12px;
    border-radius: 32px;
    .title-rank {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 700;
        font-size: 14px;
        .ranking-display-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
        }
    }

    .ranking-point {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-weight: 700;
        .point-text {
            color: #777777;
            font-weight: 500;
            white-space: nowrap;
        }
    }
}
