@import '/src/assets/styles/variables';

.filter-spot-modal-container {
    padding: 12px;

    .clear-status-container {
        gap: 10px;
        display: flex;

        .clear-status-box {
            background: $background-frame-color-default;
            border: 1px solid $detail-color-default;
            box-shadow: -3px 3px 0px $detail-color-default;
            border-radius: 8px;
            padding: 4px 10px;

            .clear-status-text {
                font-size: 12px;
            }
        }

        .clear-status-box.active {
            border: none;
            background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
            box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);

            .clear-status-text {
                color: #ffffff;
            }
        }
    }

    .category-container {
        .select-category {
            .css-hlgwow {
                margin-left: 35px !important;
            }
            .css-1fdsijx-ValueContainer {
                padding-left: 35px !important;
            }
        }

        .category-list {
            overflow-y: hidden;
            overflow-x: auto;
            display: flex;
            gap: 4px;
            margin-top: 12px;
            overflow-x: auto;
            .category-box-container {
                background: $border-frame;
                border: 1px solid $detail-color-default;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), -1px 1px 0px $detail-color-default,
                    inset 2px 2px 4px rgba(247, 240, 222, 0.4);
                border-radius: 8px;
                padding: 4px 10px;
                .category-item {
                    font-size: 12px;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }
    }
}
