@import 'assets/styles/_variables.scss';

.detail-view-container {
    box-shadow: 2px 4px 24px 0px #0000001f;
    background: #ffffff;
    position: relative;
    padding: 40px 24px;
    border-radius: 40px 40px 0px 0px;

    .detail-view-rectangle {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: radial-gradient(194.38% 88.4% at 23.08% 7.65%, #2ee7ea 0%, #14a2a4 100%);
        width: 174px;
        height: 7px;
        border-radius: 100px;
    }
}
