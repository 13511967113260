@import '/src/assets/styles/variables';

.user-info-box {
    position: relative;
    font-size: 12px;
    .user-infor-content-wrapper {
        background: $background-frame-color-default-2;
        box-shadow: inset -1px -1px 1px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        padding: 8px;
        .user-infor-content {
            background: #fef5e7;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
                inset 0px 4px 4px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            padding: 12px;
            .clear-status {
                font-size: 10px;
                display: flex;
                align-items: center;
            }

            .armoria-text {
                font-size: 12px;
                color: #895e4d;
            }

            .ranking-text {
                font-size: 16px;
            }

            .clear-status {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;

                width: 48px;
                height: 24px;
                line-height: 18px;

                /* identical to box height, or 180% */
                align-items: center;
                text-align: center;
                letter-spacing: -0.24px;

                color: #ffffff;
            }

            .not-clear {
                background: linear-gradient(0deg, #a3314b 0%, #d0627b 95.83%);
                box-shadow: -1px 2px 0px #56202c, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
            }

            .cleared {
                background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
                box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
            }

            .nick-name {
                font-size: 14px;
            }
            .category {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 10px;
                min-width: 64px;
                height: 22px;
                background: $background-frame-color-default;
                border: 1px solid $detail-color-default;
                box-shadow: -1px 1px 0px $detail-color-default;
                border-radius: 8px;

                /* Orange */
                background: #e59f21;
                border: 1px solid #895e4d;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), -1px 1px 0px #895e4d,
                    inset 2px 2px 4px rgba(247, 240, 222, 0.4);
                border-radius: 8px;

                color: #f8f8f8;
            }
            .bio {
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .user-info-box {
        .user-infor-content {
            padding: 20px;
        }
    }
}
