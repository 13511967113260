@import '/src/assets/styles/variables';

.congratulation-moda-content {
    background: linear-gradient(180deg, #fff 0%, #ffcab9 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 32px;
    padding: 0 10px 22.5px 10px;
    .congratulation-title {
        font-family: 'Optima Bold';
        background: linear-gradient(180deg, #ffdc98 8.57%, #f45800 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        font-weight: 900;
        font-size: 28px;
        margin-bottom: 4px;
    }
    .congratulation-subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 70px;
    }
    .congratulation-area {
        padding: 74px 55px 24px 55px;
        border: 1px solid;
        border-image-source: linear-gradient(180deg, #fff6e2 0%, #fac046 100%);
        box-shadow: 0px 2px 4px 0px #e08e00 inset;
        background-color: #fff;
        border-radius: 32px;
        margin-bottom: 24px;
        position: relative;

        .congratulation-area-title {
            background: #f2f2f7;
            border-radius: 100px;
            padding: 12px;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            white-space: pre-wrap;
            margin-bottom: 24px;
        }

        .ranking-text {
            font-weight: bold;
            font-size: 18px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .point-text {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
        }
        .congratulation-point-grow {
            gap: 24px;
            padding-right: 5px;
            .point-number {
                font-size: 18px;
                color: #1d1d1d;
                box-shadow: 1.16px 2.32px 9.29px 0px #ffffffcc;
                padding-bottom: 4px;
            }
            .added-point {
                position: relative;
                .plus-point {
                    position: absolute;
                    top: -15px;
                    left: 70px;
                    background: #ffefce;
                    color: #a25c09;
                    padding: 4px;
                    border-radius: 100px;
                }
            }
        }

        .medal-rank {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.new-point-detail > span:last-child {
    font-size: 12px;
}
