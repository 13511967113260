@import '/src/assets/styles/variables';

.user-detail-container {
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    .detail-header {
        background-image: url('/assets/images/header.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        border: 1px solid $detail-color-default;
        border-left: none;
        border-right: none;
        border-top: none;
        .text-header {
            font-size: 16px;
        }
    }

    .clear-status {
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px px;
        border-radius: 8px;
        width: 48px;
        height: 24px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #ffffff;
    }

    .not-clear {
        background: linear-gradient(0deg, #a3314b 0%, #d0627b 95.83%);
        box-shadow: -1px 2px 0px #56202c, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
    }

    .cleared {
        background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
        box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
    }
}

@media screen and (min-width: 768px) {
    .user-detail-container {
        max-width: 720px;
        width: 100%;
        .detail-header {
            border: 3px solid $detail-color-default;
        }
    }
}
