@import '/src/assets/styles/variables';

.success-modal-container {
    border-radius: 32px;
    .first-title,
    .second-title {
        text-align: center;
        white-space: pre-wrap;
        font-weight: bold;
        margin-bottom: 32px;
    }
    .third-title {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        color: #42bdbf;
        text-align: center;
        margin-top: -20px;
    }
    .four-title {
        font-size: 16px;
        line-height: 32px;
        font-weight: 700;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 32px;
    }
    .image-container {
        display: flex;
        align-items: center;
        min-height: 500px;
        .image {
            max-width: 100%;
            margin-bottom: 32px;
        }
    }
}
