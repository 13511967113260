@import '/src/assets/styles/variables';

.spot-item {
    background: white;
    border-radius: 32px;
    padding: 8px 16px 8px 8px;
    .spot-status {
        text-align: center;
        white-space: pre-wrap;
        font-size: 12px;
        font-weight: bold;
    }

    .image-spot {
        margin-top: 4px;
        border-radius: 16px;
        cursor: pointer;
        object-fit: cover;
        width: 100%;
        max-width: 60px;
        max-height: 60px;
        aspect-ratio: 1 / 1;
    }
    .image-spot-map {
        border-radius: 4px;
        cursor: pointer;
        object-fit: cover;
        width: 68px;
        height: 68px;
        aspect-ratio: 1 / 1;
    }
    .mission-name {
        font-weight: bold;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 13px;
        max-width: 240px;
    }
    .spot-type {
        font-size: 12px;
        color: #77b4b5;
        font-weight: bold;
    }
    .spot-clear-way {
        border-left: 1px solid var(--Grays-Gray-5, #e5e5ea);
        color: #77b4b5;
        padding: 0 12px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        white-space: nowrap;
    }
    .spot-points {
        padding-top: 4px;
        color: $text-color;
        font-size: 12px;
    }
    .mission-detail {
        font-size: 12px;
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.spot-opacity {
    opacity: 0.5;
    cursor: auto;
}
