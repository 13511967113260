@import '/src/assets/styles/variables';

.other-detail-container {
    .other-user-info-detail {
        .achievement {
            .achievement-left {
                font-size: 20px;
            }
        }

        .user-info {
            .user-avatar {
                position: relative;
                font-size: 20px;

                .edit-avatar-icon {
                    position: absolute;
                    bottom: -10px;
                    right: -10px;
                }
            }

            .user-name {
                font-size: 14px;
            }

            .user-nickname {
                font-size: 16px;
                color: $detail-color-default;
            }
        }

        .bio-component {
            font-size: 14px;
            border: $detail-color-default 3px solid;
            .input-container textarea {
                width: 100%;
                padding: 8px;
                background: #f8f8f8;
                border: 3px solid $detail-color-default;
                border-radius: 8px;
            }
        }

        .shougou {
            border: $detail-color-default 3px solid;

            .henkou_shougou_btn {
                background: $background-frame-color-default;
                border: 1px solid $detail-color-default;
                box-shadow: -2px 2px 0px $detail-color-default, inset 2px 2px 4px rgba(247, 240, 222, 0.4);
                border-radius: 8px;
                width: 64px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }

        .all-shougou {
            border: $detail-color-default 3px solid;
        }
    }

    // .other-user-bio-component,
    // .other-user-degree-component,
    // .spot-cleared-component,
    // .guess-user-component {
    //     background-color: $background-frame-color-default;
    //     border: $detail-color-default 3px solid;

    //     .other-user-best-degree {
    //         background-color: $background-frame-color-default;
    //         border: $detail-color-default 3px solid;
    //     }
    // }

    .hokanohito_no_shougou {
        background-color: $background-frame-color-default;
        border: $detail-color-default 3px solid;

        .other-user-avatar {
            font-size: 5px;
        }

        .armoria_box {
            border: $detail-color-default 3px solid;
        }
    }

    .armoria_text {
        font-size: 14px;
        color: $detail-color-default;
    }

    .armoria-detail {
        font-size: 12px;
        text-align: center;
    }

    .spot-cleared {
        font-size: 20px;
        color: #459b6d;
    }
}

@media screen and (min-width: 768px) {
    .user-detail-container {
        max-width: 720px;
        width: 100%;

        .detail-header {
            border: 3px solid $detail-color-default;
        }
    }
}
