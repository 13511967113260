@import '/src/assets/styles/variables';

.search-map {
    padding: 8px;
    .suggestion-map {
        display: flex;
        padding: 12px;
        align-items: center;
        margin-left: 3px;
        font-size: 14px;
        cursor: pointer;
        color: #414040;
        border-bottom: 1px solid #414040;
        .description {
            margin-left: 10px;
        }
    }

    .suggestion-map:last-child {
        border-bottom: none;
    }

    .suggestion-map:hover {
        background-color: $detail-color-default;
    }
}
