@import '/src/assets/styles/variables';

.my-spot-regist-detail-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .form-container {
        .form-submit-border {
            .my-spot-photo {
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
                .custom-file-image-upload {
                    background-color: transparent;
                    border: 2px dashed $background-frame-color-default-2;
                    border-radius: 32px;
                    padding: 10px 0;
                    cursor: pointer;
                    white-space: pre-wrap;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    width: 160px;
                    height: 160px;
                    background: white;
                }
                .image-spot {
                    border-radius: 32px;
                    border: none;
                    align-items: center;
                    object-fit: cover;
                }
            }
        }
        .form-submit-border.border {
            background: #f2e7ca;
            box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
                inset 0px 4px 4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            padding: 8px;
        }
    }
    .form-container.border {
        background: $border-frame;
        box-shadow: inset -1px -1px 2px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px 0.5px rgba(255, 255, 255, 0.6);
        border-radius: 8px;
        padding: 6px;
    }

    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        textarea:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px white inset;
        }
        textarea {
            width: 100%;
            padding: 12px;
            background: #f8f8f8;
            border: 1px solid #e5e5ea;
            border-radius: 32px;
            &.input-error {
                border: 1px solid red;
            }
            &:focus {
                border: 1px solid $detail-color-default;
                outline: 0 none;
            }
        }
    }
    .myspot-category-list {
        display: flex;
        overflow-x: scroll;
        gap: 10px;
    }
    .map-input {
        .input-custom .input-container input {
            padding-left: 50px !important;
        }
    }
    .delete-btn {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 36px;
    }
    .unclear-btn {
        margin: 0 auto;
    }

    .mb-10 {
        margin-bottom: 10px;
    }
    .spot-clearing-method {
        display: flex;
    }
    .myspot-QR-container {
        .myspot-QR-code {
            text-align: center;
        }
    }
}
.btn:disabled {
    border-radius: 100px;
    border: none;
    font-size: 18px;
    color: #636363;
    padding: 12px 28px;
    background: linear-gradient(180deg, rgba(205, 205, 205, 0.46) 0%, rgba(129, 129, 129, 0.46) 100%);
}

.mb-16 {
    margin-bottom: 16px;
}

.my-spot-input {
    margin-bottom: 12px;
}
