@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: normal;
    src: url('../font-family/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: normal;
    src: url('../font-family/NotoSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: bold;
    src: url('../font-family/NotoSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: bold;
    src: url('../font-family/NotoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: normal;
    src: url('../font-family/OPTIMA.woff') format('woff');
}

@font-face {
    font-family: 'Optima Italic';
    font-style: italic;
    font-weight: normal;
    src: url('../font-family/Optima_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Optima Medium';
    font-style: normal;
    font-weight: medium;
    src: url('../font-family/Optima Medium.woff') format('woff');
}

@font-face {
    font-family: 'Optima Bold';
    font-style: normal;
    font-weight: bold;
    src: url('../font-family/OPTIMA_B.woff') format('woff');
}
