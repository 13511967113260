@import '/src/assets/styles/variables';

.myspot-info-container {
    background: $border-frame;
    padding: 6px;
    box-shadow: inset -1px -1px 2px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px 0.5px rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    margin-top: 15px;
    height: 135px;
    z-index: 1;
    position: relative;

    .leaf-background {
        position: absolute;
        top: -26px;
        right: -19px;
        z-index: -1;
    }

    .myspot-info-wrapper {
        background: $background-frame-color-default;
        box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
            inset 0px 4px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        .myspot-info {
            display: flex;
            width: 89%;
            margin-left: auto;
            margin-right: auto;
            align-items: center;
            padding-top: 20px;
            .name-info {
                padding-left: 10px;
                .user-name {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: $text-color;
                    .name {
                        padding-right: 8px;
                    }
                }
            }
        }
        .my-spot-achievement-container {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            padding: 10px 27px 10px 26px;
        }
    }
}
