@import '/src/assets/styles/variables';

.self-user-info-detail {
    font-size: 12px;

    .achievement-component {
        .achievement-left {
            font-size: 20px;
        }
    }

    .user-info-component {
        .user-avatar {
            position: relative;
            font-size: 20px;

            .edit-avatar-icon {
                position: absolute;
                bottom: -10px;
                right: -10px;
            }
        }

        .user-name {
            font-size: 14px;
        }

        .user-nickname {
            font-size: 16px;
            color: $detail-color-default;
        }
        .edit-avatar-icon,
        .edit-name-icon,
        .edit-nickname-icon {
            cursor: pointer;
        }
    }

    .bio-component {
        font-size: 14px;

        .input-container textarea {
            width: 100%;
            padding: 8px;
            background: #f8f8f8;
            border: 3px solid $detail-color-default;
            border-radius: 8px;
        }
    }

    .degree-component {
        // background-image: url('../../../../../assets/images/asset2.png');
        // // background-repeat: repeat;
        // // background-position: ;
        // background-size: cover;
        // background-size: 100% 100%;
        height: 48px;
        width: 100%;
        align-items: center;
        gap: 12px;

        .henkou_degree_btn {
            background: $background-frame-color-default;
            border: 1px solid $detail-color-default;
            box-shadow: -2px 2px 0px $detail-color-default, inset 2px 2px 4px rgba(247, 240, 222, 0.4);
            border-radius: 8px;
            width: 64px;
            height: 32px;
            text-align: center;
            cursor: pointer;
        }

        .armoria_text {
            font-size: 14px;
            color: $detail-color-default;
        }
    }

    .all-degree-component {
        .armoria_text {
            font-size: 14px;
            color: $detail-color-default;
        }
    }
}
.hokanohito_no_degree {
    .other-user-avatar {
        font-size: 10px;
    }
    .other-people-nickname {
        color: $detail-color-default;
    }

    .armoria_text {
        font-size: 14px;
        color: $detail-color-default;
    }

    .armoria-detail {
        font-size: 12px;
        text-align: center;
    }

    .see-more-other-people{
        color: #3E55A7;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) {
    .self-detail-container {
        max-width: 720px;
        width: 100%;

        .detail-header {
            border: 3px solid $detail-color-default;
        }
    }
}
