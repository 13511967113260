@import '/src/assets/styles/variables';

.change-name-container {
    .btn-modify-width {
        min-width: 45%;
    }
    .title {
        text-align: center;
        white-space: pre-wrap;
        font-weight: bold;
    }
}
