@import 'assets/styles/_variables.scss';

.info-box-container {
    position: relative;
    margin-bottom: 8px;

    .info-box {
        background: #fff;
        box-shadow: 6px 4px 4px 0px #85f9ff52 inset;
        border-radius: 16px;
        text-align: center;
        padding: 12px 16px;
        font-weight: bold;
        font-size: 12px;
        max-width: 155px;

        .text {
            white-space: pre-wrap;
        }

        .close-btn {
            position: absolute;
            top: 5px;
            right: 5px;
        }
        .info-box-tail {
            position: absolute;
            bottom: -11px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
