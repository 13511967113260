@import 'assets/styles/_variables.scss';

.select-options {
    position: relative;
    input {
        font-size: 16px;
    }
    .css-b62m3t-container {
        background: #f8f8f8;
        border: 1px solid #e5e5ea;
        border-radius: 32px;
    }
    .css-t3ipsp-control {
        border: 1px solid $detail-color-default;
        box-shadow: none;
        border-radius: 32px;
        height: 46px;
    }
    .css-t3ipsp-control:hover {
        border: 1px solid $detail-color-default;
        box-shadow: none;
        border-radius: 32px;
        height: 46px;
    }

    .css-13cymwt-control {
        border-radius: 32px;
        height: 46px;
    }
    .css-13cymwt-control:hover {
        border-radius: 32px;
        height: 46px;
    }

    .css-tlfecz-indicatorContainer::before,
    .css-1gtu0rj-indicatorContainer::before {
        content: '' !important;
    }
    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-1nmdiq5-menu {
        background: #f8f8f8;
        border: 1px solid #e5e5ea;
        border-radius: 8px;
        color: #414040;
    }
    .icon-select {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
        padding: 14px;
    }
}
