@import '/src/assets/styles/variables';

.message-box-container {
    display: flex;
    margin-right: 10px;
    .image-chat {
        border-radius: 100%;
    }
    .tail-message-box {
        position: absolute;
        right: -8px;
        top: 15px;
    }
    .message-box {
        position: relative;
        padding: 12px 16px;
        border-radius: 16px;
        width: 100%;
        .comment {
            font-weight: 700;
            font-size: 12px;
            display: flex;
            align-items: center;
            word-break: break-all;
        }
        .datetime-user-message {
            margin-top: 6px;
            text-align: end;
            font-weight: 500;
            font-size: 12px;
            color: #7c7c7c;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.comment-container {
    display: flex;
    justify-content: space-between;
    .delete-comment {
        cursor: pointer;
    }
}
