@import '/src/assets/styles/variables';

.failed-moda-content {
    background: linear-gradient(180deg, #ffffff 0%, #ccc 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 32px;
    padding: 10px;
    .failed-title {
        font-family: 'Optima Bold';
        background: linear-gradient(180deg, #ffb9b9 0%, #de0d00 100%);
        text-shadow: 0 1px #fa4f4f;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        font-weight: 900;
        font-size: 28px;
        margin-bottom: 4px;
    }
    .failed-subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 24px;
    }
    .failed-area {
        padding: 24px;
        border: 1px solid #d1d1d6;
        box-shadow: 0px 2px 4px 0px #00000040 inset;
        background-color: #fff;
        border-radius: 32px;
        margin-bottom: 24px;

        .failed-area-title {
            background: #f2f2f7;
            border-radius: 100px;
            padding: 12px;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            white-space: pre-wrap;
            margin-bottom: 24px;
        }
        .point-text {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
        }
        .failed-point-grow {
            padding: 0 27px;
            .point-number {
                font-size: 18px;
                color: #1d1d1d;
                box-shadow: 1.16px 2.32px 9.29px 0px #ffffffcc;
                padding-bottom: 8px;
            }
            .added-point {
                position: relative;
                .plus-point {
                    position: absolute;
                    top: 0;
                    right: -25px;
                    background: #ffefce;
                    color: #a25c09;
                    padding: 4px;
                    border-radius: 100px;
                }
            }
        }
    }
}

.new-point-detail > span:last-child {
    font-size: 12px;
}
.message {
    font-size: 12px;
}
